import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Gallery = () => {
    const data = useStaticQuery(graphql`
    query photos {
      allFile(filter: {relativeDirectory: {eq: "photos/apartment"}}) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

    //return <strong>here</strong>
    //return <Img fluid={data.placeholderImage.childImageSharp.fluid} style={{width: '500px'}} />

    const images = data.allFile.edges

    return <div>
        <div className="tile is-ancestor">
            <div className="tile is-parent is-6">
                <div className="tile is-child">
                    <Img key={images[0].node.childImageSharp.fluid.src} fluid={images[0].node.childImageSharp.fluid}/>
                </div>
            </div>
            <div className="tile is-ancestor is-6 is-vertical">
                <div className="tile is-parent">
                    {images.slice(1, 3).map((image, index) => {
                        return <div className="tile is-parent" key={index}>
                            <div className="tile is-child">
                                <Img key={image.node.childImageSharp.fluid.src} fluid={image.node.childImageSharp.fluid}/>
                            </div>
                        </div>
                    })}
                </div>
                <div className="tile is-parent">
                    {images.slice(3, 5).map((image, index) => {
                        return <div className="tile is-parent" key={index}>
                            <div className="tile is-child">
                                <Img key={image.node.childImageSharp.fluid.src} fluid={image.node.childImageSharp.fluid}/>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
        <div className="tile is-ancestor">
            {images.slice(5, 9).map((image, index) => {
                return <div className="tile is-parent" key={index}>
                    <div className="tile is-child">
                        <Img key={image.node.childImageSharp.fluid.src} fluid={image.node.childImageSharp.fluid}/>
                    </div>
                </div>
            })}
        </div>
        <div>
            <button className="button is-primary is-medium">Show all photos</button>
        </div>
    </div>
}

export default Gallery
